<script setup>
import { useProfileStore } from "@/stores/profileStore";
import { usePaymentStore } from "@/stores/paymentStore";
import StripeCard from "@/components/StripeCard.vue";
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
const route = useRoute();
const router = useRouter();
const profileStore = useProfileStore();
const paymentStore = usePaymentStore();
const loading = ref(true);
const cancelPayment = () => {
    if (confirm("Are you sure you wish to cancel?") === true) {
        paymentStore.payment.client_secret = null;
        paymentStore.payment.status = null;
        paymentStore.payment.email = null;
        router.replace('/');
    }
}

onMounted(async () => {
    if (route.params.paymentIntentID) {
        loading.value = true;
        const url = profileStore.rootApi + "/guest/payment/" + route.params.paymentIntentID;
        let response = await fetch(url);
        if (response.status == 200) {
            let data = await response.json();
            if (data.status == "requires_payment_method") {
                paymentStore.payment = data;
                loading.value = false;
            } else {
                router.push('/paid?payment_intent=' + route.params.paymentIntentID);
            }
        }
    } else router.replace('/');
});
</script>

<template>
    <div class="paymentView container">
        <h1 class="mt-4">Payment</h1>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else-if="paymentStore.payment.client_secret" class="paymentElement">
            <StripeCard style="margin-top: 1em;" />
            <button class="btn btn-sm btn-danger mt-2" @click="cancelPayment">Cancel</button>
        </div>
    </div>
</template>
