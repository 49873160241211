import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alertStore', {
    state: () => ({
        messages: [],
        interval: null
    }),
    actions: {
        add(type, title, text) {
            if (this.interval) clearInterval(this.interval);
            this.messages.push({
                type,
                title,
                text
            });
            this.interval = setTimeout(() => {
                this.messages = [];
            }, 3500);
        }
    },
})