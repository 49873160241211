<script setup>
import { useProfileStore } from "@/stores/profileStore";
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
const profileStore = useProfileStore();
const loading = ref(true);
const output = ref({});
const route = useRoute();
const resolveStatus = (status) => {
    return (status == "requires_capture" || status == "succeeded") ? "Paid" : "Pending";
};
onMounted(async () => {
    if (route.query.payment_intent) {
        const url = profileStore.rootApi + "/guest/payment/" + route.query.payment_intent;
        let response = await fetch(url);
        let data = await response.json();
        if (response.status == 200) {
            output.value = data;
            loading.value = false;
        } else {

        }
    }
    loading.value = false;
});

</script>

<template>
    <div class="paidView mt-4 container">
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else-if="output.id" class="output" style="max-width: 700px; margin: 0 auto; text-align: center;">
            <div class="cdn-logo" style="height: 100px;">
                <img src="@/assets/cdn-logo-small.jpg" alt="C.D.N Consular Services" style="height: 100%;">
            </div>
            <h2>Thank you for your payment</h2>
            <h5>Payment Information.</h5>
            <div class="output-item">
                <div class="output-header">CDN Reference</div>
                <div class="output-data">{{ output.reference }}</div>
            </div>
            <div class="output-item">
                <div class="output-header">Receipt No</div>
                <div class="output-data">{{ output.id }}</div>
            </div>
            <div class="output-item">
                <div class="output-header">Amount</div>
                <div class="output-data">&pound;{{ output.amount.toFixed(2) }}</div>
            </div>
            <div class="output-item">
                <div class="output-header">Status</div>
                <div class="output-data">{{ resolveStatus(output.status) }}</div>
            </div>
        </div>
        <div v-else>
            <h1>Nothing to show</h1>
            <p>We cannot find any payment information to show.</p>
        </div>
    </div>
</template>
<style scoped>
.output {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.output-item {
    display: flex;
    gap: 1em;
    justify-content: center;
}
</style>
