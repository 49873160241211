<template>
    <nav class="navbar navbar-expand-sm bg-light" style="background-color: orange !important">
        <div class="container-fluid">
            <router-link to="/" style="text-decoration: none; color: inherit; font-size: 1.25em;">
                <!-- <img src="" :alt="profile.system.name" height="50" class="rounded-circle" /> -->
                <b class="ms-2">{{ profile.system.name }}</b>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                    <router-link class="nav-link" to="/dashboard">Dashboard</router-link>

                    <router-link class="nav-link" to="/profile">Profile</router-link>

                    <router-link class="nav-link" to="/password">Password</router-link>

                    <span class="nav-link" @click="logout">Logout</span>
                </ul>
            </div>
        </div>
    </nav>
</template>
<style scoped>
</style>
<script setup>
// import { computed } from 'vue';
import { useProfileStore } from "@/stores/profileStore";
import { useRouter } from 'vue-router';
import { useAlertStore } from "@/stores/alertStore";
const router = useRouter();
const profile = useProfileStore();
const alerts = useAlertStore();
const logout = async () => {
    await profile.logout();
    alerts.add('info', 'Success', 'You are logged out');
    router.push("/");
}
</script>