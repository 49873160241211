import { createRouter, createWebHistory } from 'vue-router'
import { useProfileStore } from "@/stores/profileStore";

import HomeView from '../views/Guest/HomeView.vue'
import LoginView from '@/views/Guest/LoginView.vue'
import RegisterView from '@/views/Guest/RegisterView.vue'
import ReminderView from '@/views/Guest/ReminderView.vue'
import ActivateView from '@/views/Guest/ActivateView.vue'

import PaymentView from '@/views/Guest/PaymentView.vue'
import PaidView from '@/views/Guest/PaidView.vue'

import DashboardView from '@/views/User/DashboardView.vue'
import ProfileView from '@/views/User/ProfileView.vue'
import PasswordView from '@/views/User/PasswordView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/reminder',
    name: 'reminder',
    component: ReminderView
  },
  {
    path: '/activate/:email?/:code?',
    name: 'activate',
    component: ActivateView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/password',
    name: 'password',
    component: PasswordView
  },
  {
    path: '/payment/:paymentIntentID?',
    name: 'payment',
    component: PaymentView
  },
  {
    path: '/paid',
    name: 'paid',
    component: PaidView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarCollapse = document.querySelector(".navbar-collapse");
  if (navbarToggler) navbarToggler.classList.add("collapsed");
  if (navbarCollapse) {
    navbarToggler.setAttribute("aria-expanded", false);
    navbarCollapse.classList.remove("show");
  }
  // const profile = useProfileStore();
  // const guestPaths = [
  //   '/',
  //   '/register',
  //   '/about',
  //   '/activate',
  //   '/login',
  //   '/logout',
  //   '/reminder',
  //   '/terms-and-conditions'
  // ];
  // if (profile.jwt && guestPaths.includes(to.path)) {
  //   console.log("Logged in - re-routing to Dashboard");
  //   return { path: '/dashboard' }
  // }

  // for (let g in guestPaths) {
  //   if (guestPaths[g] != "/" && to.path.substring(0, guestPaths[g].length) == guestPaths[g]) { console.log("Yes"); return true; }
  // }
  // if (!to || !to.path || profile.jwt) {
  return true;
  // } else {
  //   console.error("Access Denied", profile.jwt);
  //   return { path: '/login' };
  // }
})

export default router