import { defineStore } from 'pinia'
import { useRouter } from 'vue-router';

export const useProfileStore = defineStore('profileStore', {
    state: () => ({
        // rootDomain: 'expressdocument.co.uk',
        // rootApi: 'https://api.expressdocument.co.uk',
        system: {
            name: 'CDN Pay'
        },
        jwt: null,
        auth: {},
        remind: {},
        registration: {},
        activation: {},
        changePassword: {},
        profile: {},
        cookie: {
            path: '/',
        },
        reference: {},
        logging: [],
        captureLogging: false,
    }),
    getters: {
        rootDomain() {
            return (location.host == "pay.expressdocument.co.uk") ? 'expressdocument.co.uk' : "expressdocument.local";
        },
        rootProtocol() {
            return location.protocol + "//";
        },
        rootApi() {
            return this.rootProtocol + "api." + this.rootDomain;
        }
    },
    actions: {
        // getRootProtocol() {
        //     return location.protocol + "//";
        // },
        // getRootDomain() {
        //     const localDomains = [
        //         'localhost:8080',
        //         'app.expressdocument.local',
        //         'pay.expressdocument.local'
        //     ];
        //     return this.getRootProtocol() + location.host;
        // },
        loggingAdd(text) {
            if (this.captureLogging === true) this.logging.push(text);
        },
        // async login() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/guest/login";
        //     let status;
        //     try {
        //         let response = await fetch(url, {
        //             method: 'post',
        //             body: JSON.stringify(this.auth)
        //         })
        //         status = response.status;
        //         if (status == 200) {
        //             this.loggingAdd("Login Success");
        //             this.jwt = await response.json();
        //             this.loggingAdd(this.jwt);
        //             this.storeJWT(this.jwt);
        //             this.fetchProfile();
        //         }
        //     } catch (err) { console.log(err); }
        // },
        // async logout() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/user/logout";
        //     let status;
        //     this.logging = [];
        //     try {
        //         let response = await fetch(url, {
        //             method: 'get',
        //             headers: new Headers({
        //                 Authorization: "Bearer " + this.jwt,
        //             }),
        //         })
        //         status = response.status;
        //     } catch (err) { console.error(err) }
        //     this.jwt = null;
        //     this.profile = {};
        //     this.storeJWT(this.jwt);
        // },
        // async register() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/guest/register";
        //     let status;
        //     try {
        //         let response = await fetch(url, {
        //             method: 'post',
        //             body: JSON.stringify(this.registration)
        //         })
        //         status = response.status;
        //         if (status != 200) {
        //             return await response.json();
        //         }
        //         this.activation.email = this.registration.email;
        //         this.registration = {};
        //         return true;
        //     } catch (err) { console.log(err); }
        // },
        // async activate() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/guest/activate/" + this.activation.email + "/" + this.activation.code;
        //     let status;
        //     try {
        //         let response = await fetch(url)
        //         status = response.status;
        //         if (status == 200) {
        //             this.jwt = await response.json();
        //             this.storeJWT(this.jwt);
        //             this.fetchProfile();
        //         }
        //     } catch (err) { console.log(err); }
        // },
        // async reminder() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/guest/reminder";
        //     let status;
        //     try {
        //         let response = await fetch(url, {
        //             method: 'post',
        //             body: JSON.stringify(this.remind)
        //         })
        //         status = response.status;
        //         if (status != 200) {
        //             return await response.json();
        //         }
        //         return true;
        //     } catch (err) { console.log(err); }
        // },
        // async savePassword() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/user/password";
        //     try {
        //         let response = await fetch(url, {
        //             headers: new Headers({
        //                 Authorization: "Bearer " + this.jwt,
        //             }),
        //             method: 'post',
        //             body: JSON.stringify(this.changePassword)
        //         })
        //         if (response.status != 200) return await response.json();
        //         this.changePassword = {};
        //         return true;
        //     } catch (err) { console.log(err); }
        // },
        // async fetchProfile() {
        //     this.profile = {};
        //     if (!this.jwt) return;
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/user/profile";
        //     this.loggingAdd(url);
        //     let status;
        //     let headers = new Headers({
        //         Authorization: "Bearer " + this.jwt,
        //     });
        //     this.loggingAdd(headers);
        //     let response = await fetch(url, {
        //         method: 'get',
        //         headers: headers,
        //     })
        //     if (response.status == 200) this.profile = await response.json();
        //     this.fetchReferenceData()
        // },
        // saveProfile() {
        //     const url = this.getRootProtocol() + "api." + this.getRootDomain() + "/user/profile";
        //     const method = "patch";
        //     let status;
        //     fetch(url, {
        //         method: method,
        //         headers: new Headers({
        //             Authorization: "Bearer " + this.jwt,
        //         }),
        //         body: JSON.stringify(this.profile),
        //     })
        //         .then((response) => {
        //             status = response.status;
        //         })
        //         .catch((err) => console.error('Status', status, 'Error', err));
        // },
        // fetchJwt() {
        //     if (this.jwt) return this.jwt;
        //     let cname = 'jwt';
        //     let name = cname + "=";
        //     let decodedCookie = decodeURIComponent(document.cookie);
        //     let ca = decodedCookie.split(';');
        //     for (let i = 0; i < ca.length; i++) {
        //         let c = ca[i];
        //         while (c.charAt(0) == ' ') {
        //             c = c.substring(1);
        //         }
        //         if (c.indexOf(name) == 0) {
        //             var rlt = c.substring(name.length, c.length);
        //             this.jwt = rlt;
        //             return rlt;
        //         }
        //     }
        // },
        fetchReferenceData() {
            let url = this.getRootProtocol() + "api." + this.getRootDomain() + "/reference";
            fetch(url, {
                method: 'get',
                headers: new Headers({
                    Authorization: "Bearer " + this.jwt,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => this.reference = data)
                .catch((err) => console.error(err))
        },
        // storeJWT(jwt) {
        //     let expires;
        //     if (!jwt) {
        //         expires = "Thu, 01 Jan 1970 00:00:01 GMT";
        //     } else {
        //         let now = new Date();
        //         let time = now.getTime();
        //         let expireTime = time + 1000 * 72000;
        //         now.setTime(expireTime);
        //         expires = now.toUTCString();
        //     }
        //     var cookieTxt = "jwt=" + jwt + "; path=" + this.cookie.path + "; domain=." + this.rootDomain + "; expires = " + expires;
        //     document.cookie = cookieTxt;
        // },
    },
})