import { defineStore } from 'pinia'

export const usePaymentStore = defineStore('paymentStore', {
    state: () => ({
        // pk: 'pk_test_51LQvjzJ5yqAWVWXsaOHL7vGNy1gJ1OwdRGu5o48GP0bFSfCRLF0J2Mc54ggw09cUluIItQgkVKKRmtNyqyjEWIno00WtJBeLGZ',
        // pk: 'pk_live_51LQvjzJ5yqAWVWXs1Yrl45uLvCsU4o76CYskSpDEMMHj7sH9v6LdoqL5rPmfqbmLJzYVW4CXBTdf1JDTo1mYlwIl00xv7oBa0w',
        // stripeKeys: {
        //     live: {
        //         public: 'pk_live_51LQvjzJ5yqAWVWXs1Yrl45uLvCsU4o76CYskSpDEMMHj7sH9v6LdoqL5rPmfqbmLJzYVW4CXBTdf1JDTo1mYlwIl00xv7oBa0w'
        //     },
        //     test: {
        //         public: 'pk_test_51LQvjzJ5yqAWVWXsaOHL7vGNy1gJ1OwdRGu5o48GP0bFSfCRLF0J2Mc54ggw09cUluIItQgkVKKRmtNyqyjEWIno00WtJBeLGZ'
        //     }
        // },
        payment: {},
        currencySymbol: '£',
        reference: null,
        email: null,
        amount: null,
        client_secret: null,
        status: null,
    }),
    getters: {
        // pk() {
        //     const localDomains = [
        //         'localhost:8080',
        //         'app.expressdocument.local',
        //         'pay.expressdocument.local'
        //     ];
        //     for (let l in localDomains) {
        //         console.log("Checking", location.host.substring(0, localDomains[l].length), "against", localDomains[l]);
        //         if (location.host.substring(0, localDomains[l].length) == localDomains[l]) return this.stripeKeys.test.public;
        //     }
        //     return this.stripeKeys.live.public;
        // }
    },
    actions: {

    },
})