<template>
    <nav class="navbar navbar-expand-sm bg-light">
        <div class="container-fluid">
            <!-- <router-link to="/" style="text-decoration: none; color: inherit; font-size: 1.25em;"> -->
            <!-- <img src="" :alt="profile.system.name" height="50" class="rounded-circle" /> -->
            <b class="ms-2">{{ profile.system.name }}</b>
            <!-- </router-link> -->
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <router-link class="nav-link" to="/">Home</router-link>
                    <router-link class="nav-link" to="/payment">Payment</router-link>
                </ul>
            </div> -->
        </div>
    </nav>
</template>
<script setup>
import { useProfileStore } from "@/stores/profileStore";
const profile = useProfileStore();
</script>