<script setup>
import { ref, watch } from 'vue'
import { useProfileStore } from "@/stores/profileStore";
import { usePaymentStore } from "@/stores/paymentStore";
import { useRouter } from 'vue-router'

const message = ref({});
const loading = ref(false);
const profile = useProfileStore();
const paymentStore = usePaymentStore();
const router = useRouter();

const raisePayment = async () => {
    loading.value = true;
    if (!paymentStore.payment.amount || paymentStore.payment.amount != paymentStore.payment.amount.replace(/[^\d.-]/g, '')) {
        message.value = {
            'type': 'warning',
            'text': 'Please enter a valid amount to pay'
        }
        loading.value = false;
        return;
    }
    if (!paymentStore.payment.email) {
        message.value = {
            'type': 'warning',
            'text': 'Please enter a valid email address'
        }
        loading.value = false;
        return;
    }
    let url = profile.rootApi + '/guest/payment';
    // console.log(url);
    // if (true) return;
    let response = await fetch(url, {
        method: 'post',
        body: JSON.stringify(paymentStore.payment)
    });
    let data = await response.json();
    if (response.status != 200) {
        message.value = {
            'type': 'danger',
            'text': data
        }
        loading.value = false;
        return;
    }
    paymentStore.payment = data;
    // payment.status = data.status;
    // payment.client_secret = data.client_secret;
    loading.value = false;
    let onwardUrl = '/payment/' + paymentStore.payment.id;
    // console.log("Push to", onwardUrl)
    router.push(onwardUrl);
}

// watch(paymentStore.payment, (newPayment) => {
//     if (!newPayment.amount) return;
//     paymentStore.payment.amount = newPayment.amount.replace(/[^\d.-]/g, '');
// });
</script>

<template>
    <div class="homeView container">
        <div class="row">
            <div class="col-xs-12 col md-6">
                <h2 class="mt-4">Welcome</h2>
                <p>Payment system for CDN Consular including the ExpressDocument service.</p>
                <p>To make a payment, please enter the amount and your email address.</p>
                <p>You can also enter a reference number eg your remittance ID or our invoice number.</p>
            </div>
            <div class="col-12 col-md-6">
                <h2 class="mt-4">Payment</h2>
                <form @submit.prevent="raisePayment">
                    <label for="payment.amount">Amount</label>
                    <input type="text" v-model="paymentStore.payment.amount" id="payment.amount" class="form-control"
                        placeholder="e.g. 14.99" required>
                    <label for="payment.email">Email</label>
                    <input type="email" v-model="paymentStore.payment.email" id="payment.email" class="form-control"
                        placeholder="e.g. me@example.co.uk" required>
                    <label for="payment.reference">Reference</label>
                    <input type="text" v-model="paymentStore.payment.reference" id="payment.reference"
                        class="form-control"
                        placeholder="e.g. ABC001" required>
                    <button class="btn btn-sm btn-outline-dark mt-2">
                        <div v-if="loading" class="spinner-border spinner-border-sm text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <span v-else>
                            Continue
                        </span>
                    </button>
                </form>
                <div v-if="message.text" class="alert" :class="'alert-' + message.type" role="alert">
                    {{ message.text }}
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
button {
    width: 90px;
}

input::placeholder {
    color: #bbb;
    font-size: .9em;
    font-style: italic;
}
</style>
