<script setup>
import { loadStripe } from '@stripe/stripe-js';
import { onMounted, ref } from 'vue';
import { usePaymentStore } from "@/stores/paymentStore";
// import { useProfileStore } from "@/stores/profileStore";

const loading = ref(false);
const errorMsg = ref(null);
const paymentStore = usePaymentStore();
// const profileStore = useProfileStore();

let stripe;
let elements;
let pk = paymentStore.payment.pk;
let clientSecret = paymentStore.payment.client_secret;

const processPayment = async () => {
    errorMsg.value = null;
    loading.value = true;
    const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
            return_url: location.protocol + "//" + location.host + "/paid",
        },
    });
    loading.value = false;
    errorMsg.value = error.message;
};

onMounted(async () => {
    stripe = await loadStripe(pk);
    elements = stripe.elements({ clientSecret });
    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
});

</script>

<template>
    <div>
        <div id="payment-element">
            <!-- Stripe.js injects the Payment Element -->
        </div>
        <button id="submit" class="btn btn-sm btn-success mt-2" @click.prevent="processPayment"
            style="min-width: 150px;">
            <div v-if="loading" class="spinner-border spinner-border-sm text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>
                Pay {{ paymentStore.payment.currencySymbol }}{{ paymentStore.payment.amount.toFixed(2) }} Now
            </span>
        </button>
        <div v-if="errorMsg" class="mt-2 alert alert-warning" role="alert">
            {{ errorMsg }}
        </div>
    </div>
</template>
