<template>
    <div id="page">
        <UserNav v-if="profile.jwt" />
        <GuestNav v-else />
        <main style="min-height: 75vh;">
            <router-view />
            <Alerts />
        </main>
    </div>
</template>

<script setup>
import GuestNav from '@/Navs/Guest.Nav';
import UserNav from '@/Navs/User.Nav';
import Alerts from '@/components/Alerts';
import { useProfileStore } from "@/stores/profileStore";
import { onBeforeMount } from '@vue/runtime-core';
const profile = useProfileStore();
onBeforeMount(() => {
    // profile.fetchJwt();
    // profile.fetchProfile();
    // document.title = process.env.VUE_APP_TITLE;
    //   console.log(process.env);
})
</script>

<style lang="scss">
@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.nav-link {
    cursor: pointer;
}

footer {
    background-color: #444;
    color: white;
    padding: 2rem;
}
</style>